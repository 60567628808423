
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';

    @Component({
        components: {
            ImageComponent
        }
    })
    export default class MobileMenuSubmenuItem extends Vue {
        @Prop() item;

        active = false;

        toggleSubmenu() {
            this.active = !this.active;
        }
    }
