
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import Submenu from '@/components/blocks/menu/Submenu.vue';
    import Header from '@/components/blocks/Header.vue';
    import Button from '@/components/blocks/Button.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Form from '@/components/blocks/Form.vue';
    @Component({
        components: {
            ImageComponent,
            Submenu,
            Header,
            OptionsFieldsInlineEditor,
            Button,
            Form
        }
    })
    export default class Menu extends Vue {
        @Prop({ default: 'menu' }) menuClass;
        @Prop() items;
        @Prop() component;
        @Prop() src;
        @Prop() text;
        @Prop() textButton;
        @Prop() form;

        getGrow(items) {
            if (items.length > 5) {
                return 2;
            }
            return 1;
        }

        getActiveMenuLink(item) {
            if (
                (item.href !== '' && this.$route.path.indexOf(item.href) === 0) ||
                (item.href !== '' && item.href === this.$route.path)
            ) {
                return 'active';
            }
        }

        target(item) {
            if (item.targetBlank) {
                return '_blank';
            }
        }

        childHrefIsPath(item) {
            if (!item.hasOwnProperty('submenu') || item.submenu.length === 0) {
                return false;
            } else {
                let links: any[] = [];

                for (const submenuItem of item.submenu) {
                    links.push(...submenuItem.links);
                }

                links = links
                    .filter(link => link.href !== '' && link.href !== undefined)
                    .map(link => link.href);

                const equalsPath = href => {
                    return (
                        href !== '' &&
                        (href === `/${this.$route.params.category}` ||
                            href === this.$route.path)
                    );
                };

                return links.some(equalsPath);
            }
        }
    }
