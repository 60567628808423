
    import { Component, Prop } from 'nuxt-property-decorator';
    import ImageMixin from '@/mixins/imageMixin';

    @Component
    export default class Submenu extends ImageMixin {
        @Prop({ default: 'submenu' }) menuClass;
        @Prop() items;
        @Prop() grow;

        get style() {
            if (this.grow === 2) {
                return {
                    display: 'flex',
                    'flex-wrap': 'wrap'
                };
            }
        }

        get width() {
            if (this.grow === 2) {
                return {
                    width: '50%'
                };
            }
        }

        target(item) {
            if (item.targetBlank) {
                return '_blank';
            }
        }
    }
