
    import { Component, Emit, Prop } from 'nuxt-property-decorator';
    import { colourIsLight } from '@/utils/colors';
    import ImageMixin from '@/mixins/imageMixin';

    @Component
    export default class Color extends ImageMixin {
        @Prop() color;
        @Prop() allCount;
        @Prop() index;
        @Prop() active;
        @Prop() withDescription;

        @Emit('changeState')
        toggle() {
            return this.index;
        }

        get count(): string {
            let curColor = (this.index + 1).toString();

            return curColor + '/' + this.allCount.toString();
        }

        get style(): object {
            let background;

            if (this.color.img !== undefined && this.color.img.src) {
                background = `url("${this.imageSrc(this.color.img.src)}") ${
                    this.color.hex
                } center/cover no-repeat`;

                return { background: background };
            } else {
                background = this.color.hex.toLowerCase();

                let border = '';

                border = colourIsLight(this.color.hex) ? '#dbdbdb' : background;

                return {
                    background: background,
                    border: `1px solid ${border}`
                };
            }
        }

        get textColor() {
            if (this.color.hex) {
                return colourIsLight(this.color.hex) ? 'color: black' : 'color: white';
            }

            return '';
        }
    }
