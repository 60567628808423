
    import { Component, Prop } from 'nuxt-property-decorator';
    import ImageMixin from '@/mixins/imageMixin';
    import Submenu from '@/components/blocks/menu/Submenu.vue';
    import MobileMenuSubmenuItem from '@/components/blocks/menu/MobileMenuSubmenuItem.vue';
    import OptionsFieldsInlineEditor from '@/components/editor/options/fields/OptionsFieldsInlineEditor.vue';
    import Form from '@/components/blocks/Form.vue';

    @Component({
        components: {
            MobileMenuSubmenuItem,
            Submenu,
            OptionsFieldsInlineEditor,
            Form
        }
    })
    export default class MobileMenu extends ImageMixin {
        @Prop({ default: 'menu' }) menuClass;
        @Prop() items;
        @Prop() logo;
        @Prop() phone;
        @Prop() text;
        @Prop() form;

        activeItem = null;

        click(item) {
            this.activeItem = item;
        }

        back() {
            this.activeItem = null;
        }

        get strippedPhone() {
            return this.phone.replace(/(<([^>]+)>)/gi, '');
        }
    }
