
    import { Component, Prop, Vue } from 'nuxt-property-decorator';

    @Component
    export default class FooterSocial extends Vue {
        @Prop() links;

        get parsedLinks() {
            if (this.links !== undefined) {
                return this.links.map(link => {
                    let name = this.getSocialService(link);
                    if (name && link) {
                        return {
                            name: name,
                            href: link
                        };
                    }
                });
            }
        }

        getSocialService(link) {
            if (link && JSON.stringify(link) !== '{}') {
                if (link.indexOf('vk.com') !== -1) {
                    return 'vk';
                }
                if (link.indexOf('facebook.com') !== -1) {
                    return 'fb';
                }
                if (link.indexOf('instagram.com') !== -1) {
                    return 'im';
                }
                if (link.indexOf('youtube.com') !== -1) {
                    return 'youtube';
                }
                if (link.indexOf('t-do.ru') !== -1) {
                    return 'tg';
                }
            }
        }
    }
